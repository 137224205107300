import React, {useEffect} from "react"
import SEO from "../components/SEO"
import Logo from "../components/logo"
import Nav from "../components/nav"

export default function About() {
  
  return (
    <>
      <SEO />
      <Logo />
      <Nav />  
      <main className="entry mt-40">
        <p className="text-2xl max-w-lg mb-4 tracking-wider">Axioms & Spurs is a daily journal and exercise in concise pocket philosophy.</p>
        <p className="text-2xl max-w-lg mb-4 tracking-wider">Inspired by a collection of philosophical works, my goal is to catalog one thought or opinion a day. The name Axioms & Spurs is a nod to Frederich Nietzche's <em>Maxims & Barbs</em> from <em>Twilight of the Idols</em>. While personal and meant primarily as a journal to refer to later, this endeavor also finds inspiration from Marcus Aurelius' <em>Meditations</em>. The motivation to write axioms and spurs daily came from Ryan Holiday's <em>The Daily Stoic</em>.</p>
        <p className="text-2xl max-w-lg mb-4 tracking-wider">Philosophy is the study of ideas and fundamental questions about existence and reason. This is why you read, debate, and discuss. But there is value in exercising your own voice and allowing it to help shape your own personal philosophy. </p>
        <p className="text-2xl max-w-lg mb-4 tracking-wider">This is the humble beginning of mine.</p>
        <p className="text-2xl max-w-lg mb-4 tracking-wider">&mdash; Antoine: An ethical hedonist, cynic, and stoic</p>
      </main>
    </>
  )
}
